// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arrays-mdx": () => import("./../src/pages/arrays.mdx" /* webpackChunkName: "component---src-pages-arrays-mdx" */),
  "component---src-pages-booleans-mdx": () => import("./../src/pages/booleans.mdx" /* webpackChunkName: "component---src-pages-booleans-mdx" */),
  "component---src-pages-data-types-mdx": () => import("./../src/pages/data-types.mdx" /* webpackChunkName: "component---src-pages-data-types-mdx" */),
  "component---src-pages-drawing-mdx": () => import("./../src/pages/drawing.mdx" /* webpackChunkName: "component---src-pages-drawing-mdx" */),
  "component---src-pages-expressions-mdx": () => import("./../src/pages/expressions.mdx" /* webpackChunkName: "component---src-pages-expressions-mdx" */),
  "component---src-pages-for-loops-mdx": () => import("./../src/pages/for-loops.mdx" /* webpackChunkName: "component---src-pages-for-loops-mdx" */),
  "component---src-pages-functions-mdx": () => import("./../src/pages/functions.mdx" /* webpackChunkName: "component---src-pages-functions-mdx" */),
  "component---src-pages-fundamentals-mdx": () => import("./../src/pages/fundamentals.mdx" /* webpackChunkName: "component---src-pages-fundamentals-mdx" */),
  "component---src-pages-getting-started-mdx": () => import("./../src/pages/getting-started.mdx" /* webpackChunkName: "component---src-pages-getting-started-mdx" */),
  "component---src-pages-if-else-mdx": () => import("./../src/pages/if-else.mdx" /* webpackChunkName: "component---src-pages-if-else-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-numbers-mdx": () => import("./../src/pages/numbers.mdx" /* webpackChunkName: "component---src-pages-numbers-mdx" */),
  "component---src-pages-objects-mdx": () => import("./../src/pages/objects.mdx" /* webpackChunkName: "component---src-pages-objects-mdx" */),
  "component---src-pages-operators-mdx": () => import("./../src/pages/operators.mdx" /* webpackChunkName: "component---src-pages-operators-mdx" */),
  "component---src-pages-statements-mdx": () => import("./../src/pages/statements.mdx" /* webpackChunkName: "component---src-pages-statements-mdx" */),
  "component---src-pages-strings-mdx": () => import("./../src/pages/strings.mdx" /* webpackChunkName: "component---src-pages-strings-mdx" */),
  "component---src-pages-switch-statement-mdx": () => import("./../src/pages/switch-statement.mdx" /* webpackChunkName: "component---src-pages-switch-statement-mdx" */),
  "component---src-pages-syntax-mdx": () => import("./../src/pages/syntax.mdx" /* webpackChunkName: "component---src-pages-syntax-mdx" */),
  "component---src-pages-ternary-operator-mdx": () => import("./../src/pages/ternary-operator.mdx" /* webpackChunkName: "component---src-pages-ternary-operator-mdx" */),
  "component---src-pages-values-and-expressions-mdx": () => import("./../src/pages/values-and-expressions.mdx" /* webpackChunkName: "component---src-pages-values-and-expressions-mdx" */),
  "component---src-pages-variables-mdx": () => import("./../src/pages/variables.mdx" /* webpackChunkName: "component---src-pages-variables-mdx" */),
  "component---src-pages-what-are-data-types-mdx": () => import("./../src/pages/what-are-data-types.mdx" /* webpackChunkName: "component---src-pages-what-are-data-types-mdx" */),
  "component---src-pages-what-is-javascript-mdx": () => import("./../src/pages/what-is-javascript.mdx" /* webpackChunkName: "component---src-pages-what-is-javascript-mdx" */),
  "component---src-pages-what-is-programming-mdx": () => import("./../src/pages/what-is-programming.mdx" /* webpackChunkName: "component---src-pages-what-is-programming-mdx" */),
  "component---src-pages-while-loops-mdx": () => import("./../src/pages/while-loops.mdx" /* webpackChunkName: "component---src-pages-while-loops-mdx" */)
}

